// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import 'whatwg-fetch';
import App from './App';
import { ConfigProvider } from './config/config.context';
import PagePreview from './previews/PagePreview';
import ProgramPreview from './previews/ProgramPreview';
import SponsorPreview from './previews/SponsorPreview';
import * as serviceWorker from './serviceWorker';
import './translations/i18n';

const { basename } = window.__DATA__;

ReactDOM.render(
  <React.StrictMode>
    <Router basename={basename}>
      <ConfigProvider>
        <Switch>
          <Route path="/preview/page/:name" exact component={PagePreview} />
          <Route path="/preview/sponsor" exact component={SponsorPreview} />
          <Route path="/preview/program" exact component={ProgramPreview} />
          <Route path="/" component={App} />
        </Switch>
      </ConfigProvider>
    </Router>
  </React.StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
