import PropTypes from 'prop-types';
import React from 'react';
import BlockContainer from '../../components/BlockContainer';
import CdnImage, { cdnImagePropTypes } from '../../components/CdnImage';
import OptLink from '../../OptLink';
import { imageProptypes } from '../../propTypes';

export const ClickableImage = ({ entity, image, link, trackClick, ...props }) => (
  <OptLink entity={entity} to={link} trackClick={trackClick}>
    <CdnImage src={image} {...props} />
  </OptLink>
);

function Banner({ banner, image, link, trackClick, maxWidth, maxHeight, func, container }) {
  if (!banner && !image) return null;
  return (
    <BlockContainer className="Home--banner" type="segment-light" {...container}>
      <ClickableImage
        link={link}
        trackClick={trackClick}
        image={image || banner}
        alt="banner"
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        func={func}
        fluid
      />
    </BlockContainer>
  );
}

Banner.defaultProps = {
  banner: undefined,
  container: {},
  image: undefined,
  func: undefined,
  link: undefined,
  maxHeight: undefined,
  maxWidth: 1127,
  trackClick: undefined,
};

Banner.propTypes = {
  banner: imageProptypes,
  container: PropTypes.object,
  image: imageProptypes,
  func: cdnImagePropTypes.func,
  link: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  trackClick: PropTypes.func,
};

export default Banner;
