import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Card, Progress } from 'semantic-ui-react';
import { getString } from '../../utils';
import './QuotaField.scss';

const translationPrefix = 'workshops.card';

const QuotaField = ({ quota, usersCount }) => {
  if (!quota) return null;

  const fillingRate = Math.min(Math.round((usersCount / quota) * 100), 100);
  const availablePlaces = Math.round(100 - fillingRate);

  // prettier-ignore
  const label =
    availablePlaces === 0 ?
      getString(`${translationPrefix}.workshop-full`) :
      getString(`${translationPrefix}.available`);

  return (
    <Card.Meta className="card__progress">
      <div className={cx('details', { 'workshop-full': availablePlaces === 0 })}>
        <div className="label">{label}</div>
        {availablePlaces !== 0 && <div className="value">{availablePlaces}%</div>}
      </div>
      <Progress percent={availablePlaces} indicating size="tiny" />
    </Card.Meta>
  );
};

QuotaField.defaultProps = {
  quota: undefined,
};

QuotaField.propTypes = {
  quota: PropTypes.number,
  usersCount: PropTypes.number.isRequired,
};

export default QuotaField;
