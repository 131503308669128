import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { getString } from '../../utils';

import Chat from './Chat';
import './FloatingChat.scss';

const FloatingChat = ({ title, ...chatProps }) => {
  const [open, setOpen] = useState(false);

  return (
    <section
      className={cx('FloatingChat', {
        'FloatingChat-opened': open,
      })}
    >
      <button type="button" className="FloatingChat__Bar" onClick={() => setOpen((v) => !v)}>
        <Icon name="comment" />
        &nbsp;
        <span>{title}</span>
        <div style={{ flex: 1 }} />
        <Icon className="FloatingChat__ToggleButton" name="angle up" />
      </button>
      <Chat {...chatProps} />
    </section>
  );
};
FloatingChat.defaultProps = {
  title: getString('floatingChat.defaultTitle'),
};
FloatingChat.propTypes = {
  ...Chat.propTypes,
  title: PropTypes.string,
};

export default FloatingChat;
