import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { updateCustomTranslations } from '../translations/i18n';

const ConfigContext = React.createContext({ config: window.__DATA__, update: noop });

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(window.__DATA__ ?? {});

  const update = useCallback((newData) => {
    window.__DATA__ = {
      ...window.__DATA__,
      ...newData,
    };
    // Update i18n
    // TODO: compare
    updateCustomTranslations(window.__DATA__.lang, window.__DATA__.strings);
    setConfig(window.__DATA__);
  }, []);

  const context = useMemo(() => {
    return { config, update };
  }, [config, update]);

  return <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>;
};
ConfigProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useConfig = () => {
  return useContext(ConfigContext).config;
};
export const useUpdateConfig = () => {
  return useContext(ConfigContext).update;
};

export function withConfig(Component) {
  return function ConfigComponent(props) {
    const config = useConfig();
    return <Component {...props} config={config ?? {}} />;
  };
}
