import React from 'react';
import PropTypes from 'prop-types';
import WorkshopList from './WorkshopList';
import { getString } from '../../utils';
import BlockHeader from '../BlockHeader';

const translationPrefix = 'blocks';

const WorkshopsBlock = ({ workshopList, header, headerKey }) => (
  <>
    <BlockHeader title={header || getString(headerKey)} />
    <WorkshopList centered workshopList={workshopList} />
  </>
);

WorkshopsBlock.defaultProps = {
  header: undefined,
  headerKey: `${translationPrefix}.sessions`,
};

WorkshopsBlock.propTypes = {
  header: PropTypes.string,
  headerKey: PropTypes.string,
  workshopList: PropTypes.array.isRequired,
};

export default WorkshopsBlock;
