import PropTypes from 'prop-types';
import React from 'react';
import Blocks from '../components/Blocks';
import { cmsFields } from '../components/cms';
import { useScreenConfig } from '../config/screens.context';
import './cms.scss';

export const CmsBlocks = ({ blocks }) => {
  return <Blocks blocks={blocks} blockComponents={cmsFields} />;
};
CmsBlocks.defaultProps = {
  blocks: [],
};

CmsBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};

function CmsScreen(props) {
  const { name } = props;
  const { blocks = [] } = useScreenConfig(name);
  return (
    <div className={`screen--type-cms screen--${name}`} style={{ marginTop: 10 }}>
      <CmsBlocks blocks={blocks} />
    </div>
  );
}

CmsScreen.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CmsScreen;
