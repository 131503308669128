import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import { call, getContext, put, retry, take, takeEvery, race } from 'redux-saga/effects';
import authService from '../../core/services/auth.service';
import { LOGIN, LOGOUT } from '../auth/auth.types';
import { getAppCraftStore, setGlobalContext } from './appCraftStore.effects';

const firebaseContextKey = 'firebase';

export const FIREBASE_INITIALIZED = 'LOGGED_IN_FIREBASE';
function* getOrInitializeFirebaseApp(config) {
  const oldApp = yield getContext(firebaseContextKey);
  if (oldApp?.name === config.projectId) {
    return oldApp;
  }
  try {
    yield oldApp?.delete();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
  const nextApp = firebase.initializeApp(config, config.projectId);
  nextApp.firestore().settings({
    ignoreUndefinedProperties: true,
  });

  return nextApp;
}

// To handle renewal locally and prevent infinite renewal loop.
function* invalidTokenRetry(app) {
  const store = yield getAppCraftStore();
  const payload = yield authService.renewFirebaseToken(store.token);

  yield app.auth().signInWithCustomToken(payload.firebase.token);
}

function* firebaseSignIn(app, token) {
  try {
    if (app.auth().currentUser) return;
    yield app.auth().signInWithCustomToken(token);
  } catch (e) {
    retry(3, 500, invalidTokenRetry, app);
  }
}
function signOut(app) {
  if (!app.auth().currentUser) {
    return Promise.resolve();
  }
  return app.auth().signOut();
}

const configure = (firebasePayload) => {
  return call(function* updateFirebaseContext() {
    if (!firebasePayload?.config) return;

    try {
      const { config, token } = firebasePayload;
      const store = yield getAppCraftStore();
      const app = yield* getOrInitializeFirebaseApp(config);
      yield* firebaseSignIn(app, token);

      const firebaseAuth = app.auth();

      yield firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.NONE);

      const authUnsub = firebaseAuth.onAuthStateChanged((user) => {
        if (user) return;
        store.renewFirebaseToken();
      });

      yield setGlobalContext({
        [firebaseContextKey]: app,
      });

      yield put({
        type: FIREBASE_INITIALIZED,
      });

      yield race({ out: take(LOGOUT), in: take(LOGIN) });
      authUnsub();
      yield signOut(app);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to initialize Firebase', e);
    }
  });
};

export const getFirebase = () => {
  return call(function* _getFirebase() {
    const app = yield getContext(firebaseContextKey);
    if (!app) {
      yield take(FIREBASE_INITIALIZED);
      return yield getFirebase();
    }
    return app;
  });
};
export const getFirestore = () =>
  call(function* _getFirestore() {
    const app = yield getFirebase();
    return app.firestore();
  });
export const getFireAuth = () =>
  call(function* _getAuth() {
    const app = yield getFirebase();
    return app.auth();
  });

function* autoConfiguration(event) {
  yield configure(event.payload?.firebase);
}

export function* firebaseSaga() {
  yield takeEvery(LOGIN, autoConfiguration);
}
