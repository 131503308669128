// eslint-disable-next-line import/no-cycle
import workshopSessionService from '../services/workshop-session.service';
import { SET } from './workshopSessions.types';

export function setWorkshopSessions(workshopSessions) {
  return {
    type: SET,
    workshopSessions,
  };
}

export function fetchWorkshopSessions() {
  return async (dispatch) => {
    const workshopSessions = await workshopSessionService.fetchEventWorkshopSessions();
    if (workshopSessions) {
      dispatch(setWorkshopSessions(workshopSessions));
    }
  };
}
