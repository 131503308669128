import React from 'react';
import { bem } from '../../../core/design/bem';
import BlockContainer from '../../BlockContainer';
import { BlockProps, ContainerProps } from '../types';
import './FaqBlock.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Faq = require('react-faq-component').default;

const css = bem('FaqBlock');

type RowProps = {
  title: string;
  content: number;
};
type QuestionsProps = {
  title?: string;
  rows: RowProps[];
};

type FaqBlockProps = BlockProps & {
  blocks: any[];
  className?: string;
  container?: ContainerProps;
  questions?: QuestionsProps;
} & typeof FaqBlockDefaultProps;

const FaqBlockDefaultProps = {
  container: {},
  className: '',
  questions: {},
};

const FaqBlock = ({ _id, className, container, questions }: FaqBlockProps): JSX.Element => {
  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      <Faq data={questions} />
    </BlockContainer>
  );
};

FaqBlock.defaultProps = FaqBlockDefaultProps;

export default FaqBlock;
