import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Icon } from 'semantic-ui-react';
import BlockContainer from '../components/BlockContainer';
import SquareImage from '../components/SquareImage';
import VideoCard from '../components/video/VideoCard';
import WorkshopsMatchingBlock from '../components/workshops/WorkshopsMatchingBlock';
import { useScreenConfig } from '../config/screens.context';
import { useSpeakers } from '../core/users/users.hooks';
import useTranslations from '../hooks/useTranslations';
import { containerTypePropTypes } from '../propTypes';

const translationPrefix = 'blocks';

const VideosBlock = ({ videos, container }) => {
  const { t } = useTranslation();
  const visibleVideos = videos.filter((video) => video.file || video.value);
  if (visibleVideos.length === 0) return null;

  return (
    <BlockContainer {...container} header={container?.header || t(`${translationPrefix}.videos`)}>
      <Card.Group centered>
        {visibleVideos.map((video, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <VideoCard key={index} video={video} />;
        })}
      </Card.Group>
    </BlockContainer>
  );
};
VideosBlock.defaultProps = {
  container: undefined,
  videos: [],
};
VideosBlock.propTypes = {
  container: containerTypePropTypes,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.sting,
    }),
  ),
};

export const SpeakerHeader = ({ fluid, template, speaker }) => {
  const { company, description, firstName, lastName, role, thumbnail, url } = speaker;

  const isLightTemplate = template === 'light';
  return (
    <Card className="card--speaker" fluid={fluid}>
      {thumbnail && (
        <div>
          <SquareImage
            as="img"
            src={thumbnail}
            alt={`${firstName} ${lastName}`}
            className="avatar large"
            imageSize={200}
          />
        </div>
      )}
      <Card.Content className="content">
        <Card.Header>
          {firstName} {lastName}
        </Card.Header>
        {!isLightTemplate && <Card.Description>{company}</Card.Description>}
        <Card.Meta>
          <span className="date">{role} </span>
        </Card.Meta>
        {url && (
          <Card.Meta>
            <a href={url} rel="noreferrer noopener" className="field-link" target="_blank">
              {url}{' '}
            </a>
          </Card.Meta>
        )}
        {description && !isLightTemplate && (
          <Card.Description style={{ textAlign: 'justify' }}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  );
};

SpeakerHeader.defaultProps = {
  fluid: undefined,
  template: undefined,
};
SpeakerHeader.propTypes = {
  fluid: PropTypes.bool,
  template: PropTypes.string,
  speaker: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
};

const videoContainer = { type: 'basic' };

const Speaker = ({ match }) => {
  const { showSessions = true } = useScreenConfig('speaker');
  const speakers = useSpeakers();
  const { id } = match.params;
  const { t } = useTranslations('speakers.speaker');

  const speaker = speakers.find((s) => (s.slug ? s.slug === id : s._id === id));
  if (!speaker) return null;
  return (
    <div className={`screen--type-speaker speaker--${speaker._id}`}>
      <Button
        className="back-button"
        style={{ backgroundColor: 'transparent', paddingLeft: 0, color: 'white' }}
        as={Link}
        to="/speakers"
      >
        <Icon name="angle left" /> {t('back-to-speakers')}
      </Button>
      <Grid>
        <Grid.Column>
          <SpeakerHeader fluid speaker={speaker} />
          {showSessions && <WorkshopsMatchingBlock field="speakers" value={speaker._id} />}
          <VideosBlock videos={speaker.videos} container={videoContainer} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

Speaker.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Speaker;
