// eslint-disable-next-line import/no-cycle
import platformService from '../../core/services/platform.service';
import { SET_ALL, SET_BY_ID } from './programs.types';

export const setPrograms = (programs) => ({
  type: SET_ALL,
  programs,
});

export const setProgram = (program) => ({
  type: SET_BY_ID,
  program,
});

export function fetchPrograms() {
  return async (dispatch) => {
    const workshops = await platformService.fetchPrograms();
    dispatch(setPrograms(workshops));
  };
}
export function fetchProgram(workshopId, options = {}) {
  return async (dispatch) => {
    const workshop = await platformService.fetchProgram(workshopId, options);
    dispatch(setProgram(workshop));
  };
}
