// eslint-disable-next-line import/no-cycle
import platformService from '../../core/services/platform.service';
import { SET } from './agenda.types';

export function setAgenda(agenda) {
  return {
    type: SET,
    agenda,
  };
}

export function fetchAgenda() {
  return async (dispatch) => {
    const { agenda } = await platformService.fetchAgenda();
    if (agenda) {
      dispatch(setAgenda(agenda));
    }
  };
}
