import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import store from '../shared/Store';

const Context = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [firebase, setFirebase] = useState(null);

  useEffect(() => {
    store.getFirebase().then((fb) => {
      setFirebase(fb);
    });
  }, []);
  return <Context.Provider value={firebase}>{children}</Context.Provider>;
};
FirebaseProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

export const useFirebase = () => {
  return useContext(Context);
};

export const useFirestoreQuery = (query) => {
  const [data, setData] = useState([]);

  useEffect(
    () =>
      query?.onSnapshot({
        next(snapshot) {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(docs);
        },
      }),
    [query],
  );

  return data;
};

const identity = (v) => v;
export const useFirestoreCollection = (ref, queryFilter = identity) => {
  const query = useMemo(() => queryFilter(ref), [queryFilter, ref]);
  const data = useFirestoreQuery(query);

  return useMemo(
    () => ({
      list: data,
      async create(newOne) {
        await ref.add(newOne);
      },
      async update(id, update) {
        await ref.doc(id).update(update);
      },
      async set(id, next) {
        await ref.doc(id).set(next);
      },
      async remove(id) {
        await ref.doc(id).delete();
      },
    }),
    [ref, data],
  );
};

export const useFirestoreDocument = (ref) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    return ref?.onSnapshot({
      next(snapshot) {
        if (!snapshot.exists) {
          setData(null);
          return;
        }

        const doc = {
          ...snapshot.data(),
          id: snapshot.id,
        };

        setData(doc);
      },
    });
  }, [ref]);

  return {
    item: data,
    async remove() {
      await ref.delete();
    },
    async set(next, options) {
      await ref.set(next, options);
    },
    async update(update, options) {
      await ref.update(update, options);
    },
  };
};
