/* eslint-disable react/no-danger */
import React from 'react';
import './SpeakerCard.scss';
import PropTypes from 'prop-types';
import { Card, Icon, Label } from 'semantic-ui-react';
import { getString } from '../../utils';
import SquareImage from '../SquareImage';
import EntityLink from '../EntityLink';

const translationPrefix = 'speakers.card';

const speakerThumbnailOptions = {
  type: 'auto', // Allow webp
};

const CardInfo = ({ icon, style, children, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
    {icon && <Icon name={icon} />} {children}
  </Card.Description>
);

const CardBoolTag = ({ icon, label, color, style, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', marginTop: 8, ...style }}>
    <Label color={color} icon={icon} content={label} />
  </Card.Description>
);

const cardMetaComponents = {
  extra: Card.Meta,
  description: Card.Description,
  info: CardInfo,
  boolTag: CardBoolTag,
};

const SpeakerCard = ({
  imageSize,
  fluid,
  template,
  speaker,
  width,
  extraFields,
  showLink,
  children,
}) => {
  const { company, shortDescription, firstName, lastName, role, thumbnail } = speaker;

  const isLightTemplate = template === 'light';
  return (
    <Card
      className="card--speaker"
      style={{ width }}
      fluid={fluid}
      as={showLink ? EntityLink : undefined}
      entity={speaker}
    >
      {thumbnail && (
        <div>
          <SquareImage
            as="img"
            alt={`${firstName} ${lastName}`}
            src={thumbnail}
            imageSize={imageSize}
            cdnOptions={speakerThumbnailOptions}
            className={`avatar ${template || ''}`}
          />
        </div>
      )}
      <Card.Content className="content">
        <Card.Header>
          {firstName} {lastName}
        </Card.Header>
        {company && <Card.Description>{company}</Card.Description>}
        {role && (
          <Card.Meta>
            <span className="date">{role} </span>
          </Card.Meta>
        )}
        {extraFields &&
          extraFields
            .filter((field) => speaker[field.dataKey])
            .map(({ lineType, dataKey, className, ...fieldProps }) => {
              const value = speaker[dataKey];
              const Component = cardMetaComponents[lineType] || Card.Meta;
              return (
                <Component
                  key={dataKey}
                  className={`${className || ''} field-${dataKey}`}
                  {...fieldProps}
                >
                  {value}
                </Component>
              );
            })}
        {shortDescription && !isLightTemplate && (
          <Card.Description>{shortDescription}</Card.Description>
        )}
      </Card.Content>
      {showLink && (
        <Card.Content className="navlink">
          <EntityLink autoHide entity={speaker}>
            {getString(`${translationPrefix}.view-sessions`)}
          </EntityLink>
        </Card.Content>
      )}
      {children}
    </Card>
  );
};

SpeakerCard.defaultProps = {
  children: undefined,
  extraFields: [],
  fluid: undefined,
  imageSize: 200,
  showLink: true,
  template: undefined,
  width: undefined,
};
SpeakerCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  extraFields: PropTypes.arrayOf(PropTypes.shape({ dataKey: PropTypes.string })),
  fluid: PropTypes.bool,
  imageSize: PropTypes.number,
  showLink: PropTypes.bool,
  template: PropTypes.string,
  speaker: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  width: PropTypes.number,
};

export default SpeakerCard;
