import { addMinutes, differenceInMinutes, format, formatISO, parseISO } from 'date-fns';
import sumBy from 'lodash/sumBy';
import times from 'lodash/times';
import { ensureDate } from '../../../utils/date';

export function formatTime(date) {
  return format(ensureDate(date), "HH'h'mm");
}

export function hasAvailableSlots(slots) {
  if (!slots) return false;
  return !!slots.find((slot) => slot.isAvailable);
}

export function countAvailableSlots(slots) {
  if (!slots) return 0;
  return sumBy(slots, (slot) => (slot.isAvailable ? 1 : 0));
}

export function generateSlots(startDate, endDate, duration, available) {
  const date = parseISO(startDate);
  const minutes = differenceInMinutes(parseISO(endDate), date);
  const slotDuration = duration * 60 * 1000;
  return times(minutes / duration, (n) => {
    const sDate = date.getTime() + n * slotDuration;
    return {
      startDate: formatISO(sDate),
      endDate: formatISO(addMinutes(sDate, 15)),
      isAvailable: available ? available(sDate, n) : false,
    };
  });
}
