/* eslint-disable react/no-array-index-key */
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Button, Card, Header } from 'semantic-ui-react';
import { useTracking } from '../../../Context';
import { eventTags } from '../../../core/trackers/events';
import { containerTypePropTypes } from '../../../propTypes';
import { getString } from '../../../utils';
import BlockContainer from '../../BlockContainer';
import CdnImage from '../../CdnImage';
import { isImage, isPDF } from '../utils';
import ImageLightbox from './components/ImageLightbox';
import PDFModal from './components/PDFModal';
import './FilesBlock.scss';
import { linkable } from './utils';

const LinkableCard = linkable(Card);
const LinkableContent = linkable(Card.Content);

function sanitizeFile(file) {
  return {
    ...file,
    originalFilename: file.originalFilename || file.value?.originalFilename,
    uri: file.uri || file.value?.uri,
  };
}

export function useFileTracking(block) {
  const { trackEvent } = useTracking();
  return useMemo(() => {
    function onDownload(file) {
      trackEvent(eventTags.DOWNLOAD_FILE, {
        file: { ...file, value: file }, // TODO: clean up formats
        item: block, // Parent item => cms block
      });
    }
    function onView(file) {
      trackEvent(eventTags.VIEW_FILE, {
        file: { ...file, value: file }, // TODO: clean up formats
        item: block, // Parent item => cms block
      });
    }
    return { onDownload, onView };
  }, [block, trackEvent]);
}

function FilesBlock(props) {
  const { title, subtitle, files, containerType } = props;

  const [lightbox, setLightbox] = useState(null);
  const [pdfToView, setPdfToView] = useState(null);

  const { onDownload, onView } = useFileTracking(props);

  const cleanFiles = useMemo(() => {
    if (!files) return [];
    return files.map(sanitizeFile);
  }, [files]);

  if (cleanFiles.length === 0) return null;

  return (
    <BlockContainer type={containerType} className="block--cms-files">
      <Header as="h3" style={{ marginBottom: 0 }}>
        {title}
      </Header>
      <Header as="h4" style={{ marginTop: 0 }}>
        {subtitle}
      </Header>
      <Card.Group>
        {cleanFiles.map((file, index) => {
          const { title: fileTitle, originalFilename, uri, thumbnail, description } = file;
          const width = 260;

          const isCardClickable = isImage(uri) || isPDF(uri);

          function setCurrentFile() {
            onView(file);
            if (isImage(uri)) {
              // is image
              const images = cleanFiles.filter((f) => isImage(f.uri));
              setLightbox({ images, currentIndex: findIndex(images, (i) => i.uri === uri) });
            } else if (isPDF(uri)) {
              setPdfToView(file);
            }
          }

          return (
            <LinkableCard
              key={index}
              href={!isCardClickable && uri}
              onClick={isCardClickable ? setCurrentFile : () => onDownload(file)}
            >
              <Card.Content className="content--image">
                <CdnImage
                  src={thumbnail || uri}
                  maxWidth={width}
                  maxHeight={Math.round((width / 21) * 29.7)}
                  imageType="jpg"
                />
              </Card.Content>
              <Card.Content style={{ flex: 0 }}>
                <Card.Header className={!fileTitle ? 'break-all' : undefined}>
                  {fileTitle || originalFilename}
                </Card.Header>
                {description && <Card.Meta>{description}</Card.Meta>}
              </Card.Content>
              <LinkableContent
                href={isCardClickable && uri}
                style={{ flex: 0 }}
                onClick={isCardClickable && uri ? () => onDownload(file) : undefined}
              >
                <Button fluid>{getString('btn.download')}</Button>
              </LinkableContent>
            </LinkableCard>
          );
        })}
      </Card.Group>
      {lightbox && <ImageLightbox {...lightbox} onClose={() => setLightbox(null)} />}
      {pdfToView && <PDFModal file={pdfToView} onClose={() => setPdfToView(null)} />}
    </BlockContainer>
  );
}

FilesBlock.defaultProps = {
  containerType: 'segment',
  files: [],
  subtitle: undefined,
  title: undefined,
};

FilesBlock.propTypes = {
  containerType: containerTypePropTypes,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default FilesBlock;
