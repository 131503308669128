import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Image } from 'semantic-ui-react';
import Images, { cloudimg, extractFormat } from '../utils/Images';

function double(n) {
  if (typeof n === 'number') return n * 2;
  return n;
}

export const cdnImagePropTypes = {
  imageType: PropTypes.oneOf(['auto', 'original', 'jpg']),
  func: PropTypes.oneOf(['bound', 'crop', 'fit']),
};

const CdnImage = (allProps) => {
  const {
    as: Component,
    className,
    src,
    srcSet,
    maxWidth,
    maxHeight,
    func,
    imageType,
    cdnOptions,
    ...props
  } = allProps;
  if (!maxWidth && !maxHeight) {
    // Turn into CDN...
    const url = Images.cdn(src);
    if (!url) return null;

    return <Component src={url} className={className} {...props} />;
  }

  const imageSrc = cloudimg(src, {
    w: maxWidth,
    h: maxHeight,
    func,
    org_if_sml: 1,
    force_format: extractFormat(imageType),
    ...cdnOptions,
  });
  if (!imageSrc) return null;

  function computeSrcSet() {
    if (typeof srcSet === 'string') return srcSet;
    if (srcSet === true && (maxWidth || maxHeight)) {
      // Check if image big enough
      if (src.width && maxWidth && src.width <= maxWidth * 2) return undefined;
      if (src.height && maxHeight && src.height <= maxHeight * 2) return undefined;

      return `${cloudimg(src, {
        w: double(maxWidth),
        h: double(maxHeight),
        func,
        org_if_sml: 1,
        force_format: extractFormat(imageType),
        ...cdnOptions,
      })} 2x`;
    }
    return undefined;
  }

  return <Component src={imageSrc} srcSet={computeSrcSet()} className={className} {...props} />;
};

CdnImage.defaultProps = {
  as: Image,
  cdnOptions: undefined,
  className: '',
  imageType: 'auto',
  func: 'bound',
  maxHeight: undefined,
  maxWidth: undefined,
  src: undefined,
  srcSet: true,
};

CdnImage.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  cdnOptions: PropTypes.object,
  className: PropTypes.string,
  imageType: cdnImagePropTypes.imageType,
  func: cdnImagePropTypes.func,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  srcSet: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const ImageWithAspectRatio = (props) => {
  const { src } = props;
  if (!src) return null;

  const aspectRatio = Images.aspectRatio(src);
  if (!aspectRatio) return <CdnImage {...props} />;
  return (
    <div className="image-ratio-wrapper" style={{ paddingTop: `${100 / aspectRatio}%` }}>
      <CdnImage {...props} />
    </div>
  );
};

ImageWithAspectRatio.propTypes = CdnImage.propTypes;

export default CdnImage;
