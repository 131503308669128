import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useUserStore(collection) {
  return useSelector((state) => state.users.collections[collection]);
}

export function useUserIdList(collection, ids) {
  const userStore = useUserStore(collection);
  return useMemo(() => {
    if (!userStore || !ids) return [];
    const { usersById } = userStore;
    return ids?.map((id) => usersById[id]).filter((v) => v);
  }, [userStore, ids]);
}

export function useUser(collection, id) {
  return (useUserStore(collection)?.usersById || {})[id];
}

export function useUserCollection(collection) {
  return useSelector((state) => state.users.collections[collection]?.users || []);
}

export function useSpeaker(id) {
  return useUser('speakers', id);
}

export function useSpeakers() {
  return useUserCollection('speakers');
}

export function useSpeakerIds(ids) {
  return useUserIdList('speakers', ids);
}
