import { formatISO, parseISO } from 'date-fns';
import { every, pickBy } from 'lodash';
import flatten from 'lodash/flatten';
import deburr from 'lodash/fp/deburr';
import isEmpty from 'lodash/fp/isEmpty';
import negate from 'lodash/fp/negate';
import words from 'lodash/fp/words';
import get from 'lodash/get';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

function dayOfDate(date) {
  if (!date) return date;
  return date.replace(' ', 'T').split('T')[0];
}

function normalizeDate(date) {
  if (!date) return date;
  return formatISO(parseISO(date.replace(' ', 'T')));
}

export function toggleFilter(filters, key, value) {
  if (filters[key] === value) {
    return omit(filters, key);
  }
  return { ...filters, [key]: value };
}

export function formatFieldValue(item, field) {
  return []
    .concat(field)
    .map((v) => get(item, v))
    .join(' ');
}

export function formatSearchContent(item, searchFields) {
  const content = searchFields
    .map((searchField) => formatFieldValue(item, searchField))
    .filter(negate(isEmpty))
    .join(' ')
    .toLowerCase();
  return deburr(content);
}

export function searchAsWordList(searchValue) {
  return words(deburr(searchValue.toLowerCase()));
}

export function textSearch(searchWords, content) {
  return searchWords.every((word) => content.includes(word));
}

function hasSearchValue(item, searchWords, fields) {
  const index = formatSearchContent(item, fields);
  return textSearch(searchWords, index);
}

export function applySearchFilter(data, filterValue, fields) {
  if (!filterValue) return data;
  const searchWords = searchAsWordList(filterValue);
  return data.filter((item) => hasSearchValue(item, searchWords, fields));
}

function applyDefaultFilter(filters, item) {
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in filters) {
    const value = filters[key];
    const itemValue = item[key];
    if (value && !itemValue) return false;
    if (value === false && !itemValue) return true;
    if (Array.isArray(itemValue)) {
      if (!itemValue.includes(value)) return false;
      // eslint-disable-next-line eqeqeq
    } else if (itemValue != value) {
      return false;
    }
  }
  return true;
}

export function getSearchField(list) {
  return list?.find((filter) => filter.type === 'search');
}

function applyDateFilter(data, field, value) {
  const { dataKey } = field;
  return data.filter((item) => value === dayOfDate(item[dataKey]));
}

function applyDateTimeFilter(data, field, value) {
  const { dataKey } = field;
  return data.filter((item) => value === normalizeDate(item[dataKey]));
}

const specificFilterFuncs = {
  date: applyDateFilter,
  datetime: applyDateTimeFilter,
};

export function applyFilters(data, filters, filterList) {
  if (!filters) return data;

  let filteredData = data;
  let remainingFilters = filters;
  const searchField = getSearchField(filterList);
  if (searchField) {
    remainingFilters = omit(remainingFilters, searchField.dataKey);
    filteredData = applySearchFilter(
      filteredData,
      filters[searchField.dataKey],
      searchField.fields,
    );
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const field of filterList) {
    const filterField = specificFilterFuncs[field.type];
    if (filterField) {
      const { dataKey } = field;
      const filterValue = filters[dataKey];
      remainingFilters = omit(remainingFilters, dataKey);
      if (filterValue) {
        filteredData = filterField(filteredData, field, filterValue);
      }
    }
  }

  return filteredData.filter((item) => applyDefaultFilter(remainingFilters, item));
}

function extractValues(data, key) {
  const values = uniq(
    flatten(
      data.map((d) => {
        const v = d[key];
        if (!v) return [];
        if (Array.isArray(v)) return v;

        return [v];
      }),
    ),
  );
  return orderBy(values);
}

function extractDateValues(data, key) {
  const values = uniq(data.map((d) => dayOfDate(d[key]))).filter((v) => v);
  return orderBy(values);
}
function extractDateTimeValues(data, key) {
  const values = uniq(data.map((d) => normalizeDate(d[key]))).filter((v) => v);
  return orderBy(values);
}

function valueToOption(value) {
  if (typeof value === 'string') return { value, label: value };
  return value;
}

function injectOptionCounts(filter, data) {
  return filter.values.map((option) => ({
    ...option,
    count: applyFilters(data, { [filter.dataKey]: option.value }, [filter]).length,
  }));
}

// eslint-disable-next-line import/prefer-default-export
export function generateFilters(data, filters, currentFilters) {
  return (
    filters
      .map((filter) => {
        const { type = 'simple' } = filter;
        if (type === 'simple') {
          return {
            ...filter,
            values: (filter.values || extractValues(data, filter.dataKey)).map(valueToOption),
          };
        }
        if (type === 'date' && !filter.values) {
          return {
            ...filter,
            values: extractDateValues(data, filter.dataKey).map(valueToOption),
          };
        }
        if (type === 'datetime' && !filter.values) {
          return {
            ...filter,
            values: extractDateTimeValues(data, filter.dataKey).map(valueToOption),
          };
        }
        return filter;
      })
      // Inject counts
      .map((filter) => {
        if (!currentFilters || !filter.values) return filter; // Don't count
        const dataWithoutFilter = applyFilters(data, omit(currentFilters, filter.dataKey), filters);
        return {
          ...filter,
          countForAll: dataWithoutFilter.length,
          values: injectOptionCounts(filter, dataWithoutFilter).filter(
            (opt) => opt.count > 0 || !filter.hideEmptyOptions,
          ),
        };
      })
  );
}

function isEmptyArray(value) {
  return Array.isArray(value) && value.length === 0;
}

function isValueMatch(objValue, matchValue) {
  if (Array.isArray(objValue)) {
    // Match any array
    return !!objValue.find((v) => v === matchValue);
  }
  return objValue === matchValue;
}

export function matchesWithArray(matchObject) {
  // Only keep filled values
  const match = pickBy(matchObject, (v) => !!v && !isEmptyArray(v));
  const matchers = Object.keys(match).map((key) => {
    const value = match[key];
    if (Array.isArray(value)) {
      // Match one of
      const matchValues = value;
      return (obj) => {
        const objValue = obj[key];
        // eslint-disable-next-line no-restricted-syntax
        for (const v of matchValues) {
          if (isValueMatch(objValue, v)) return true;
        }
        return false;
      };
    }
    // Exact match
    return (obj) => isValueMatch(obj[key], value);
  });
  return (obj) => {
    return every(matchers, (matcher) => matcher(obj));
  };
}
