import keyBy from 'lodash/keyBy';
import { SET } from './agenda.types';

const initialState = {
  agenda: [],
  agendaById: {},
};

export default function reduceAgenda(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return { agenda: action.agenda, agendaById: keyBy(action.agenda, '_id') };
    }
    default:
      return state;
  }
}
