import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';
import { useSpeakerIds } from '../../core/users/users.hooks';
import { getString } from '../../utils';
import BlockHeader from '../BlockHeader';
import SpeakerCard from './SpeakerCard';

const translationPrefix = 'blocks';

const SpeakersBlock = ({ ids }) => {
  const speakers = useSpeakerIds(ids);
  if (!speakers || speakers.length === 0) return null;

  return (
    <>
      <BlockHeader title={getString(`${translationPrefix}.speakers`)} />
      <Card.Group itemsPerRow={3} centered>
        {speakers.map((speaker) => (
          <SpeakerCard key={speaker._id} speaker={speaker} />
        ))}
      </Card.Group>
    </>
  );
};
SpeakersBlock.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SpeakersBlock;
