import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import CdnImage from '../components/CdnImage';
import ExpiringNavLink from '../components/ExpiringNavLink';
import Footer from '../components/Footer';
import PageBackground from '../components/PageBackground';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import Styles from '../Styles';
import { getString } from '../utils';
import './authentication.scss';
import SideSection from './components/SideSection';

const translationPrefix = 'login';

const sideSectionPropType = PropTypes.shape({
  side: PropTypes.oneOf(['left', 'right']),
  blocks: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })),
});

const FullScreenContainer = ({ className, children, sideSection }) => {
  const { visible: sideVisible = true, side = 'right' } = sideSection || {};
  const hasSection = sideSection && sideVisible && !!sideSection?.blocks.length;
  const sidebar = hasSection && <SideSection sidebar={sideSection} />;
  return (
    <div className={cx('fullscreen-container', className)}>
      <div className="fullscreen-center-wrapper">
        <Grid textAlign="center" stackable relaxed verticalAlign="middle">
          {side === 'left' && sidebar && <Grid.Column width={1} />}
          {side === 'left' && sidebar}
          {hasSection && <Grid.Column width={1} />}
          <Grid.Column className="column--auth-content" width={hasSection ? 4 : undefined}>
            {children}
          </Grid.Column>
          {hasSection && <Grid.Column width={1} />}
          {side !== 'left' && sidebar}
          {side !== 'left' && sidebar && <Grid.Column width={1} />}
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

FullScreenContainer.defaultProps = {
  className: undefined,
  sideSection: undefined,
};

FullScreenContainer.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  sideSection: sideSectionPropType,
};

const LoginBanner = () => {
  const design = useDesignConfig();
  const { banner } = design;
  // eslint-disable-next-line no-undef
  if (!banner) return null;
  return (
    <div className="LoginBanner">
      <a href="/" target="_blank">
        <CdnImage src={banner} alt="salon-banner" maxWidth={1200} />
      </a>
    </div>
  );
};
const LoginFooter = () => {
  const design = useDesignConfig();
  const { loginFooter } = design;

  if (!loginFooter) return null;
  return <CdnImage className="LoginFooter" src={loginFooter} />;
};

const LoginLayout = ({ children, error, screen, sideSection }) => {
  const {
    loginBackground,
    loginBackgroundVideo,
    loginLogo,
    loginLogoProps,
    primaryColor: color,
  } = useDesignConfig();
  const config = useConfig();
  const { isPlatformClosed } = config;

  return (
    <>
      <Styles />
      <PageBackground image={loginBackground} video={loginBackgroundVideo} />
      <FullScreenContainer
        className={cx('screen-auth', screen && `screen-auth--${screen}`)}
        sideSection={isPlatformClosed ? undefined : sideSection}
      >
        {loginLogo && (
          <ExpiringNavLink to="/">
            <CdnImage
              className="authentication--logo"
              as="img"
              src={loginLogo}
              maxWidth={422}
              maxHeight={100}
              alt="event logo"
              {...loginLogoProps}
            />
          </ExpiringNavLink>
        )}
        <p className="authentication--welcome">
          {getString(`${translationPrefix}.welcome-to-the-platform`)}
        </p>
        <Form size="large" error={!!error}>
          <Segment className="authentication--container">
            <LoginBanner />
            {isPlatformClosed && (
              <div className="platform-closed">
                <Header as="h2" style={{ color }}>
                  {getString(`${translationPrefix}.platform-closed-title`)}
                </Header>
                <p>{getString(`${translationPrefix}.platform-closed-content`)}</p>
              </div>
            )}
            {!isPlatformClosed && children}
          </Segment>
        </Form>
        <LoginFooter />
      </FullScreenContainer>
    </>
  );
};

LoginLayout.defaultProps = {
  error: false,
  screen: undefined,
};
LoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
  error: PropTypes.bool,
  screen: PropTypes.string,
};

export default LoginLayout;
