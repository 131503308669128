import { format, parseISO } from 'date-fns';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
// import moment from 'moment';
// import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import moment from 'moment-timezone';
import 'moment/locale/fr';

moment.locale(window.__DATA__?.lang || 'fr');

const { eventTimezone, forceForEveryone } = window.__DATA__?.options?.timezone || {};

export const momentTz =
  eventTimezone && forceForEveryone ? (date) => moment(date).tz(eventTimezone) : moment;

export function forceTimezone() {
  return eventTimezone && forceForEveryone;
}

export const locales = { fr, en };

export function dateFNSLocale() {
  return locales[(window.__DATA__ || {}).lang] || fr;
}

export function ensureDate(date) {
  if (typeof date === 'string') return parseISO(date);
  return new Date(date);
}

export function localeFormat(date, dateFormat) {
  if (!date) return '';
  return format(ensureDate(date), dateFormat, { locale: dateFNSLocale() });
}

export function getTimeZone() {
  if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions) {
    return Intl.DateTimeFormat().resolvedOptions();
  }
  return undefined;
}
