import { parseISO } from 'date-fns';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import trim from 'lodash/trim';
import decode from 'querystring/decode';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getParameterValueByName } from '.';
import { useConfig } from '../config/config.context';
import { useTracking } from '../Context';
import { applyFilters, getSearchField } from '../core/filter/utils';
import { replaceValues, urlJoin } from './stringUtils';
import { entityForEvent } from '../agenda/blocks/UserAgendaBlock';
import { useEntityPath } from '../components/EntityLink';

const debugNow = getParameterValueByName('debugNow');
export const debugNowTime = debugNow ? parseISO(debugNow).getTime() : undefined;

function generateLowerCaseOrder(field) {
  return (item) => {
    const v = item[field];
    if (typeof v === 'string') return v.toLowerCase();
    return v;
  };
}

// eslint-disable-next-line import/prefer-default-export
export function useOrderBy(items, itemOrderBy) {
  return useMemo(() => {
    if (!itemOrderBy || itemOrderBy.length === 0) return items;

    const fields = itemOrderBy.map((o) => generateLowerCaseOrder(o.field));
    const orders = itemOrderBy.map((o) => o.order);
    return orderBy(items, fields, orders);
  }, [items, itemOrderBy]);
}

function useRouteSearchField() {
  const location = useLocation();
  const [initialSearchValue] = useState(() => decode(location.search.slice(1)).q);
  return initialSearchValue;
}

export function usePreFilter(data, preFilters) {
  const allFilters = useMemo(() => preFilters && pickBy(preFilters, (v) => !!v), [preFilters]);
  const result = useMemo(() => applyFilters(data, allFilters, []), [data, allFilters]);
  return result;
}

export function useFilter(data, filterList, defaultFilters = {}) {
  const initialSearchValue = useRouteSearchField();
  const [currentFilters, setFilters] = useState(() => {
    const searchField = getSearchField(filterList);
    if (initialSearchValue && searchField) {
      return {
        [searchField.dataKey]: initialSearchValue,
      };
    }
    return {};
  });

  const allFilters = useMemo(
    () => pickBy({ ...currentFilters, ...defaultFilters }, (v) => v != null),
    [currentFilters, defaultFilters],
  );
  const result = useMemo(() => applyFilters(data, allFilters, filterList), [
    data,
    allFilters,
    filterList,
  ]);
  return [result, allFilters, setFilters];
}

export const useMountEffect = (fun) => useEffect(fun, []);
export const useAsyncMountEffect = (fun) =>
  useEffect(() => {
    fun();
  }, []);

export function useAutoRefresh(milliseconds) {
  const [now, setNow] = useState(Date.now());
  useMountEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, milliseconds);
    return () => clearInterval(interval);
  });
  return debugNowTime || now;
}

function generateUrl(basename, lang, canonicalPath, item, externalLink) {
  if (!canonicalPath) return externalLink ? window.location.href : '';

  const baseUrlValue = externalLink ? `${window.location.protocol}//${window.location.host}` : '';
  const path = urlJoin([
    externalLink ? basename : '',
    replaceValues(canonicalPath, {
      ...item,
      lang,
    }),
  ]);
  return `${baseUrlValue}/${trim(path, '/')}`;
}

export const useEntityUrl = (entity, externalLink = true) => {
  const { basename, lang, screens } = useConfig();
  const path = useEntityPath(entity);
  return useMemo(() => {
    return generateUrl(basename, lang, path, entity, externalLink);
  }, [basename, lang, entity, screens, externalLink]);
};

export const useCalendarEventLink = (event, externalLink = true) => {
  return useEntityUrl(entityForEvent(event), externalLink);
};

export const useWorkshopTracker = (tag, entity) => {
  const hasClicked = useRef(false);
  const { trackEvent } = useTracking();

  function onInferredClick(currentReplay) {
    // Only track the first click to know if launched
    if (!hasClicked.current) {
      trackEvent(tag, { workshop: entity, replay: currentReplay });
      hasClicked.current = true;
    }
  }

  return onInferredClick;
};
