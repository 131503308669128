import React, { useState } from 'react';
import { userArrayProptypes } from '../../../propTypes';
import { avatarWithFallbackWhenMissing } from '../../../utils/avatarUtils';
import ItemGridBlock from '../../cms/ItemGridBlock';
import SpeakerModal from '../components/SpeakerModal';

const DetailsVariant = (props) => {
  const { users, ...rest } = props;
  const [selectedUser, setSelectedUser] = useState(undefined);
  const { imageKey, imageSize, showModal } = rest;
  const items = users.map((user) => ({
    ...user,
    title: `${user.firstName || ''} ${user.lastName || ''}`,
    fullDescription: user.description,
    description: user.role,
    image: avatarWithFallbackWhenMissing(user[imageKey]),
  }));
  return (
    <div>
      <ItemGridBlock
        item={{ imageSize }}
        container={{ type: 'headerless' }}
        grid={{ doubling: true }}
        items={items}
        onClick={(item) => (showModal ? setSelectedUser(item) : undefined)}
      />
      {selectedUser && (
        <SpeakerModal speaker={selectedUser} onClose={() => setSelectedUser(undefined)} />
      )}
    </div>
  );
};

DetailsVariant.propTypes = { users: userArrayProptypes };
DetailsVariant.defaultProps = { users: [] };

export default DetailsVariant;
