import { matches, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useConfig } from '../config/config.context';
import { useScreenConfig } from '../config/screens.context';
import { replacePath, replaceValues } from '../utils/stringUtils';
import ExpiringNavLink from './ExpiringNavLink';

function computePath(entity, itemConfig = {}, screensConfig) {
  const { kind, parentPath } = entity;
  const { path, disableItemPage } = itemConfig;
  if (disableItemPage) return null;

  if (kind) {
    switch (kind) {
      case 'workshop':
        return `${path || entity.collection}/${entity.slug || entity._id}`;
      case 'workshops': {
        if (itemConfig?.workshop?.canonicalPath) {
          return replaceValues(itemConfig?.workshop?.canonicalPath, entity);
        }
        if (itemConfig?.[entity.collection]?.canonicalPath) {
          return replaceValues(itemConfig?.[entity.collection]?.canonicalPath, entity);
        }
        if (parentPath) return parentPath;
        return replaceValues(`${path || entity.collection}`, entity);

        // return `${path || entity.collection}`;
      }
      case 'offer': {
        return `/rdv/offers/${entity._id}`;
      }
      // ICI un kind:exihbitor ? pour gérer les url ?

      // eslint-disable-next-line no-empty
      default: {
      }
      // eslint-disable-next-line no-console
      // console.warn('Unhandled entity "kind" path for', entity);
      // return null;
    }
  }

  // Search for

  if (path && typeof path === 'string') return replaceValues(path, entity);
  switch (entity.collection) {
    case 'speakers':
      return `/speakers/${entity.slug || entity._id}`;
    case 'sponsors':
      return `/sponsors/${entity.slug || entity._id}`;
    case 'program': {
      const canonicalPath = screensConfig?.programs?.program?.canonicalPath;
      if (canonicalPath) return replaceValues(canonicalPath, entity);
      return `/programs/${entity.slug || entity._id}`;
    }
    case 'workshops':
    case 'live':
    case 'atelier':
    case 'ateliers': {
      const { workshopPath = '/schedule/{category}/{slug}' } = itemConfig.workshops || {};
      return replaceValues(workshopPath, entity);
    }
    case 'workshop-live': {
      const { workshopPath = '/live/{slug}' } = itemConfig.workshops || {};
      return replaceValues(workshopPath, entity);
    }
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled entity path for', entity);
      return null;
  }
}

function findPageFor(screens, entity) {
  return values(screens).find((page) => {
    const { path, entities } = page;
    return path && entities && entities.find((pageEntity) => matches(pageEntity)(entity));
  });
}

export function useEntityPath(entity) {
  // TODO: introduce entity.kind/type ?
  const { screens } = useConfig();
  const itemConfig = useScreenConfig(
    entity?.kind === 'workshops' ? 'workshops' : entity?.collection,
  );

  let path;
  const page = findPageFor(screens, entity);
  if (page) {
    path = replacePath(page.path, entity);
    if (path) return path;
  }

  // Legacy mode
  return computePath(entity, itemConfig, screens);
}

const EntityLink = ({ children, entity, autoHide, ...props }) => {
  const path = useEntityPath(entity);

  if (!path) {
    if (autoHide) return null; // Hide link
    return <div {...props}>{children}</div>; // Default to div
  }
  return (
    <ExpiringNavLink {...props} to={path}>
      {children}
    </ExpiringNavLink>
  );
};

EntityLink.defaultProps = {
  autoHide: false,
};

EntityLink.propTypes = {
  autoHide: PropTypes.bool, // If used as a link and not a component, auto-hide link
  children: PropTypes.node.isRequired,
  entity: PropTypes.object.isRequired,
};

export default EntityLink;
