import React from 'react';
import PropTypes from 'prop-types';
import { Header, Item, Segment } from 'semantic-ui-react';
import { getString } from '../../utils';

const ContactsBlock = ({ item, field, header }) => {
  const contacts = item[field];
  if (!contacts || contacts.length === 0) return null;

  return (
    <Segment>
      <Header as="h3">{header || getString(`sponsors.sponsor.contact-information`)}</Header>
      <Item.Group link>
        {contacts.map((contact) => (
          <Item key={contact._id} as="a" href={`mailto:${contact.email}`}>
            <Item.Content>
              <Item.Header style={{ fontSize: '1.2em' }}>
                {contact.firstName} {contact.lastName}
              </Item.Header>
              {contact.phoneNumber && <Item.Extra>{contact.phoneNumber}</Item.Extra>}
              <Item.Meta>{contact.email}</Item.Meta>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </Segment>
  );
};

ContactsBlock.defaultProps = {
  field: 'contacts',
  header: undefined,
};

ContactsBlock.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object.isRequired,
  header: PropTypes.string,
};

export default ContactsBlock;
