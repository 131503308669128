// eslint-disable-next-line import/no-cycle
import platformService from '../services/platform.service';
import { SET_COLLECTION } from './users.types';

export const setUsers = (collection, users) => ({
  type: SET_COLLECTION,
  collection,
  users,
});

export function fetchUsersByCollection(collection) {
  return async (dispatch) => {
    const users = await platformService.fetchUsers(collection);
    // TODO: handle errors
    if (Array.isArray(users)) {
      dispatch(setUsers(collection, users));
    }
  };
}
