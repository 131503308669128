import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import FileList, { hasValidUri } from '../../components/FileList';
import { getString } from '../../utils';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import './FilesBlock.scss';

const tagsByCollection = {
  sponsors: eventTags.SPONSOR_DOWNLOAD_FILE,
  program: eventTags.PROGRAM_DOWNLOAD_FILE,
};

const FilesBlock = ({
  className,
  item,
  field,
  header,
  headerKey,
  showThumbnail,
  showDescription,
  translationPrefix,
}) => {
  const { trackEvent } = useTracking();

  const files = item[field];
  if (!files) return null;
  const validFiles = files.filter(hasValidUri);
  if (validFiles.length === 0) return null;

  function trackDownload(file) {
    // Generic tag first
    trackEvent(eventTags.DOWNLOAD_FILE, {
      field,
      file,
      item, // Parent item
    });
    // Custom one for OVH :/
    trackEvent(tagsByCollection[item.collection], {
      field,
      file,
      item, // Parent item
    });
  }

  return (
    <Segment className={className}>
      <Header as="h3">{header || getString(`${translationPrefix}.${headerKey}`)}</Header>
      <FileList
        files={validFiles}
        showThumbnail={showThumbnail}
        showDescription={showDescription}
        onDownload={trackDownload}
      />
    </Segment>
  );
};

FilesBlock.defaultProps = {
  className: 'block--files',
  header: undefined,
  headerKey: 'files',
  showDescription: true,
  showThumbnail: false,
  translationPrefix: 'sponsors.sponsor',
};

FilesBlock.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  header: PropTypes.string,
  headerKey: PropTypes.string,
  showDescription: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  translationPrefix: PropTypes.string,
};

export default FilesBlock;
