import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Embed, Modal } from 'semantic-ui-react';
import OptLink from '../../../OptLink';
import Blocks from '../../Blocks';
import './ActionsBlock.scss';

const IframeModal = ({ url, onClose }) => (
  <Modal className="modal--iframe" closeIcon onClose={onClose} open size="large">
    <Modal.Content>
      <Embed active url={url} iframe={{ allow: 'fullscreen;camera;microphone;display-capture' }} />
      {/* <iframe title="modal" src={url} allow="fullscreen;camera;microphone;display-capture" /> */}
    </Modal.Content>
  </Modal>
);

export const Action = ({ type, color, variant, text, url, style }) => {
  const [showModal, setShowModal] = useState(false);

  function handleClick(e) {
    if (type === 'iframe-modal') {
      e.preventDefault();
      e.stopPropagation();

      // Open
      setShowModal(true);
    }
  }

  return (
    <>
      <Button
        primary={color === 'primary'}
        className={variant === 'link' ? 'button--link' : undefined}
        style={variant === 'link' ? { color, ...style } : style}
        as={OptLink}
        to={url}
        onClick={handleClick}
      >
        {text}
      </Button>
      {showModal && <IframeModal url={url} onClose={() => setShowModal(false)} />}
    </>
  );
};

Action.defaultProps = {
  color: undefined,
  style: {},
  type: undefined,
  variant: 'button',
};

Action.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['button', 'primary', 'link']),
};

const blockComponents = { action: Action };

function ActionsBlock({ blocks }) {
  if (!blocks || blocks.length === 0) return null;
  return (
    <div className="block--actions">
      <Blocks blocks={blocks} blockComponents={blockComponents} />
    </div>
  );
}

ActionsBlock.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })).isRequired,
};

export default ActionsBlock;
