import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Segment } from 'semantic-ui-react';
import './VideoBlock.scss';
import VideoEmbed from '../../components/VideoEmbed';
import VideoTracker from '../../core/trackers/VideoTracker';

const VideoBlock = ({ title, video, allowFullScreen, videoProps }) => (
  <Grid.Row stretched className="home-block--video">
    <Grid.Column width={16}>
      <Segment.Group>
        {title && (
          <Segment>
            <Header as="h3">{title}</Header>
          </Segment>
        )}
        {video && (
          <Segment className="segment--video">
            <VideoTracker item={video} video={video} videoType="video" />
            <VideoEmbed
              defaultActive
              allowFullScreen={allowFullScreen}
              {...videoProps}
              url={video.uri}
              {...video}
            />
          </Segment>
        )}
      </Segment.Group>
    </Grid.Column>
  </Grid.Row>
);

VideoBlock.defaultProps = {
  allowFullScreen: true,
  title: undefined,
  videoProps: {},
};

VideoBlock.propTypes = {
  allowFullScreen: PropTypes.bool,
  title: PropTypes.string,
  video: PropTypes.shape({
    uri: PropTypes.string,
    source: PropTypes.string, // Ex : "youtube"
    id: PropTypes.string, // Youtube id
  }).isRequired,
  videoProps: PropTypes.shape({
    defaultActive: PropTypes.bool,
  }),
};

export default VideoBlock;
