import ActionsBlock from './ActionsBlock/ActionsBlock';
import DividerBlock from './DividerBlock';
import HtmlBlock from './HtmlBlock';
import ImageBlock from './ImageBlock';
import LinksBlock from './LinksBlock';

export default {
  actions: ActionsBlock,
  divider: DividerBlock,
  header: HtmlBlock,
  html: HtmlBlock,
  image: ImageBlock,
  links: LinksBlock,
};
