import cx from 'classnames';
import { isBefore, parseISO } from 'date-fns';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { stringify } from 'querystring';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BlockContainer from '../../components/BlockContainer';
import CdnImage from '../../components/CdnImage';
import VideoEmbed from '../../components/VideoEmbed';
import { useScreenConfig } from '../../config/screens.context';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import VideoTracker from '../../core/trackers/VideoTracker';
import { useTimeLeft } from '../../home/blocks/CountdownBlock';
import { countdownVariants } from '../../home/blocks/NextLiveCountdownBlock/variants';
import { containerTypePropTypes, imageProptypes } from '../../propTypes';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { useAutoRefresh } from '../../utils/hooks';
import Poster from '../components/Poster';
import './WorkshopLiveBlock.scss';

const translationPrefix = 'workshops.workshop';

function injectUserData(url) {
  if (url.indexOf('userId') !== -1) {
    return url;
  }

  // Time to inject
  const { user } = store;
  const qs = stringify({
    userId: user._id,
    firstName: user.firstName,
    lastName: user.lastName,
    thumbnail: user.thumbnail?.uri,
  });
  const glue = url.indexOf('?') === -1 ? '?' : '&';
  return url + glue + qs;
}

const LiveCountdown = ({
  className,
  containerType,
  header,
  backgroundImage,
  label,
  template,
  item,
  ...rest
}) => {
  const timeLeft = useTimeLeft(item?.startDate);
  const Component = (template && get(countdownVariants, template)) || countdownVariants.square;
  return (
    <BlockContainer
      className={cx(className, 'block--video', 'block--video--countdown')}
      type={containerType}
      header={header}
    >
      <CdnImage src={backgroundImage} maxWidth={1200} />
      <div className="block--video--countdown__container">
        <div className="countdown__label">{label}</div>
        <Component {...rest} timeLeft={timeLeft} type={template} {...rest} />
      </div>
    </BlockContainer>
  );
};

const WorkshopLiveBlock = ({
  allowFullScreen,
  className,
  containerType,
  field,
  item,
  registration,
  videoProps,
  replayField,
  title,
  countdown,
  preLiveImage,
  postLiveImage,
}) => {
  const { mandatory } = item;
  const videos = item[field] || [];

  const { trackEvent } = useTracking();
  const { workshop = {} } = useScreenConfig('workshops');
  const { liveAvailableOnlyForRegistered = false, countdownMode = 'default' } = workshop;
  const now = useAutoRefresh(60000); // TODO: voir pour le passer à 5 minutes
  const startAt = parseISO(item.startDate);

  function onViewLive() {
    trackEvent(eventTags.WORKSHOP_VIEW_LIVE, {
      userId: store.user._id,
      workshop: item,
    });
  }
  function onClickLive() {
    trackEvent(eventTags.WORKSHOP_CLICK_LIVE, {
      userId: store.user._id,
      workshop: item,
    });
  }

  // Track on mount ?
  const autoHide = liveAvailableOnlyForRegistered && !registration && !mandatory;
  const hasVideos = !autoHide && videos && videos.length > 0;
  const hasReplays = item[replayField]?.length;
  useEffect(() => {
    if (hasVideos) {
      onViewLive();
    }
  }, [hasVideos, item._id]);

  if (autoHide) {
    return null;
  }

  if (!hasVideos && !hasReplays) {
    if (isBefore(now, startAt) && countdown?.backgroundImage && countdown?.label) {
      return (
        <LiveCountdown
          containerType={containerType}
          item={item}
          header={title || getString(`${translationPrefix}.live-streaming`)}
          className={className}
          {...countdown}
        />
      );
    }
    return (
      <Poster
        countdownMode={countdownMode}
        item={item}
        header={title || getString(`${translationPrefix}.live-streaming`)}
        className="block--video"
        containerType={containerType}
        postLiveImage={item.postLiveImage?.image ? item.postLiveImage : postLiveImage}
        preLiveImage={item.preLiveImage?.image ? item.preLiveImage : preLiveImage}
      />
    );
  }
  if (!hasVideos) return null;

  const video = videos[0];
  const { qaUri } = video;
  return (
    <BlockContainer
      className={cx(className, 'block--video')}
      type={containerType}
      header={title || getString(`${translationPrefix}.live-streaming`)}
    >
      <VideoTracker item={item} video={video} videoType="live" />
      <div style={{ position: 'relative', flexShrink: 0, flexGrow: 1 }}>
        <VideoEmbed
          icon="play"
          className="block--video__embed"
          defaultActive
          allowFullScreen={allowFullScreen}
          iframe={{
            allowFullScreen,
          }}
          {...videoProps}
          {...video}
          url={video.uri}
          onClick={onClickLive}
        />
      </div>
      {qaUri && <iframe className="block--video__qa" title="Q&A" src={injectUserData(qaUri)} />}
    </BlockContainer>
  );
};

WorkshopLiveBlock.defaultProps = {
  allowFullScreen: true,
  className: undefined,
  containerType: 'segment',
  countdown: undefined,
  field: 'liveStreams',
  replayField: 'replays',
  title: undefined,
  registration: undefined,
  videoProps: {},
  preLiveImage: undefined,
  postLiveImage: undefined,
};
WorkshopLiveBlock.propTypes = {
  allowFullScreen: PropTypes.bool,
  className: PropTypes.string,
  containerType: containerTypePropTypes,
  countdown: PropTypes.shape({
    backgroundImage: imageProptypes,
    label: PropTypes.string,
  }),
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  registration: PropTypes.object,
  replayField: PropTypes.string,
  title: PropTypes.string,
  preLiveImage: PropTypes.object,
  postLiveImage: PropTypes.object,
  videoProps: PropTypes.shape({
    defaultActive: PropTypes.bool,
  }),
};

const ConnectedWorkshopLiveBlock = connect((state, props) => ({
  registrations: state.registrations,
  registration: state.registrations.registrationsById[props.item._id],
}))(WorkshopLiveBlock);

export default ConnectedWorkshopLiveBlock;
