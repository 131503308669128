import React, { useEffect, useRef } from 'react';
// import React from 'react';
import PropTypes from 'prop-types';
import { Embed } from 'semantic-ui-react';
// import Iframe from 'react-iframe-click/src/index.tsx';
import { parseVideo } from '../utils/videoUtils';
import './VideoEmbed.scss';
import store from '../shared/Store';
import { replaceUriValues } from '../utils/stringUtils';
import { useMe } from '../profile/hooks';
import { eventTags } from '../core/trackers/events';
import { useTracking } from '../Context';

export function EmbedWithIframeListener({ onInferredClick, iframe, ...props }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === 'iframe' &&
        iframeRef.current === document.activeElement &&
        onInferredClick
      ) {
        onInferredClick(iframeRef.current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return <Embed {...props} iframe={{ ...iframe, ref: iframeRef }} />;
}

EmbedWithIframeListener.defaultProps = {
  iframe: undefined,
  onInferredClick: undefined,
};

EmbedWithIframeListener.propTypes = {
  iframe: PropTypes.object,
  onInferredClick: PropTypes.func,
};

const VideoEmbed = ({ url, id, allowFullScreen, autoPlay, ...props }) => {
  const me = useMe();
  const { trackEvent } = useTracking();
  // Check video type...
  if (!url && !id) return null; // Nothing to show

  if (!url && id) {
    // Fallback to default embed mode
    return <EmbedWithIframeListener id={id} {...props} />;
  }

  const { type, id: videoId, url: embedUrl } = parseVideo(url);
  const customUrl = replaceUriValues(embedUrl, { ...me, userId: store?.user?._id });
  switch (type) {
    case 'youtube': {
      return (
        <EmbedWithIframeListener autoplay={autoPlay} {...props} id={videoId} source="youtube" />
      );
    }
    case 'video': {
      return (
        <Embed {...props}>
          <video
            autoPlay={autoPlay}
            allowFullScreen={allowFullScreen}
            controls
            onClick={() => trackEvent(eventTags.VIDEO_VIEW, props)}
          >
            <source src={customUrl} type="video/mp4" />
          </video>
        </Embed>
      );
    }
    case 'meeting': {
      const { iframe, ...otherProps } = props;
      return (
        <EmbedWithIframeListener
          iframe={{
            allowFullScreen,
            ...iframe,
            allow: 'fullscreen;camera;microphone;display-capture',
          }}
          {...otherProps}
          url={customUrl}
        />
      );
    }

    default: {
      const { iframe, ...otherProps } = props;
      return (
        <EmbedWithIframeListener
          iframe={{
            allowFullScreen,
            allow: 'fullscreen;camera;microphone;display-capture',
            ...iframe,
          }}
          {...otherProps}
          url={customUrl}
        />
      );
    }
  }
};

VideoEmbed.defaultProps = {
  allowFullScreen: true,
  autoPlay: false,
  id: undefined,
  url: undefined,
};

VideoEmbed.propTypes = {
  allowFullScreen: PropTypes.bool,
  autoPlay: PropTypes.bool,
  id: PropTypes.string,
  url: PropTypes.string,
};

export default VideoEmbed;
