export function normalizeString(string) {
  if (!string) return '';
  return string.trim();
}

export function checkPasswordValidity(password) {
  return !(!password || password.length < 8);
}

export function checkPasswordsSimilarity(password, confirmPassword) {
  return normalizeString(password) === normalizeString(confirmPassword);
}
