/* eslint-disable @typescript-eslint/no-unused-vars */
import get from 'lodash/get';
import NukaCarousel from 'nuka-carousel';
import React from 'react';
import { useMedia } from 'react-media';
import { Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';
import './CarouselBlock.scss';
import { DocumentVariant, ImageVariant, TextVariant, VideoVariant } from './itemVariants';
import LiStyles from './LiStyles';
import { CarouselImageProps, Item } from './types';

const css = bem('CarouselBlock');

const components = {
  document: DocumentVariant,
  image: ImageVariant,
  video: VideoVariant,
  text: TextVariant,
};

type CarouselProps = {
  _id: string;
  container?: ContainerProps;
  imageProps?: CarouselImageProps;
  itemVariant?: 'image' | 'video' | 'text';
  items?: Item[];
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  carousel?: Record<string, any>;
} & typeof CarouselDefaultProps;

const CarouselDefaultProps = {
  container: {},
  carousel: {},
  className: '',
  imageProps: undefined,
  items: [],
  itemVariant: 'image',
};

const CarouselBlock = ({
  _id,
  items,
  itemVariant,
  imageProps,
  container,
  carousel,
  className,
}: CarouselProps): JSX.Element | null => {
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  if (items.length === 0) return null;
  const ItemComponent = get(components, itemVariant, ImageVariant);
  const slidesToShow = matches.desktop ? carousel?.slidesToShow : 1;

  return (
    <BlockContainer {...container} className={css({ id: _id, itemVariant }).mix(className)}>
      <LiStyles _id={_id} items={items} />
      <NukaCarousel
        {...carousel}
        slidesToShow={slidesToShow}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => (
          <div>
            <div className="test" />
            <Icon name="chevron left" onClick={previousSlide} disabled={currentSlide === 0} />
          </div>
        )}
        renderCenterRightControls={({ nextSlide, currentSlide }) => (
          <div>
            <div className="test" />
            <Icon
              onClick={nextSlide}
              name="chevron right"
              disabled={currentSlide === items.length - slidesToShow}
            />
          </div>
        )}
      >
        {items.map((item) => {
          return <ItemComponent {...item} imageProps={imageProps} />;
        })}
      </NukaCarousel>
    </BlockContainer>
  );
};

CarouselBlock.defaultProps = CarouselDefaultProps;

export default CarouselBlock;
