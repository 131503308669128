import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { parseISO } from 'date-fns';
import { dateFNSLocale, forceTimezone, localeFormat, momentTz } from '../../utils/date';

function formatDate(date) {
  const isoDate = parseISO(date);
  if (forceTimezone()) {
    return {
      date: momentTz(isoDate).format('LL'),
      time: momentTz(isoDate).format('LT z'),
    };
  }
  const dateOptions = { locale: dateFNSLocale() };
  return {
    date: localeFormat(isoDate, 'PP', dateOptions),
    time: localeFormat(isoDate, 'p', dateOptions),
  };
}

const WorkshopDate = ({ startDate, endDate, showEndTime }) => {
  if (!startDate) return null;
  const { date, time: startTime } = formatDate(startDate);
  const { time: endTime } = formatDate(endDate);
  return (
    <span className="datetime">
      <span className="date" style={{ marginRight: 10 }}>
        <Icon name="calendar alternate outline" />
        {date}
      </span>
      <span className="time">
        <Icon name="clock" />
        {startTime} {`${showEndTime && endDate ? `- ${endTime}` : ''}`}
      </span>
    </span>
  );
};

WorkshopDate.defaultProps = {
  startDate: undefined,
  endDate: undefined,
  showEndTime: false,
};

WorkshopDate.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  showEndTime: PropTypes.bool,
};

export default WorkshopDate;
