import flatten from 'lodash/flatten';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { eventChannel } from 'redux-saga';
import { all, put, race, retry, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import plateformService from '../../core/services/platform.service';
import { LOGIN, LOGOUT } from '../../store/auth/auth.types';
import { getAppCraftStore } from '../../store/effects/appCraftStore.effects';
import { getFirestore } from '../../store/effects/firebase.effects';
import { pipeChannel } from '../../store/effects/sagas.effects';
import { notify } from '../../store/notifications/notifications.actions';
import { getString } from '../../utils';
import ChatStatus from './ChatStatus';
import {
  chatListLoaded,
  chatLoaded,
  chatRemoved,
  inviteReceived,
  inviteSent
} from './networking.actions';
// eslint-disable-next-line import/no-cycle
import { extractDataFromDoc } from './networking.helpers';
import {
  ACCEPT_INVITE,
  NetworkingChatsCollectionKey,
  NetworkingCollectionKey,
  REFUSE_INVITE,
  SEND_INVITE
} from './networking.types';

async function loadParticipants(chat, currentUser) {
  const currentUserInvited = chat.invited === currentUser?._id;

  const otherParticipantId = currentUserInvited ? chat.inviter : chat.invited;
  const otherParticipant = await plateformService.fetchNetworkingParticipant(otherParticipantId);
  if (!otherParticipant) return null;

  if (currentUserInvited) {
    return {
      ...chat,
      invitedProfile: currentUser,
      inviterProfile: otherParticipant,
    };
  }
  return {
    ...chat,
    invitedProfile: otherParticipant,
    inviterProfile: currentUser,
  };
}

async function mapInvitesChanges(currentUser, change) {
  const chatId = change.doc.id;
  const data = extractDataFromDoc(change.doc);
  if (change.type === 'removed') {
    return [chatRemoved(chatId)];
  }
  const currentUserInvited = data.invited === currentUser?._id;
  if (change.type === 'added') {
    const next = await loadParticipants(data, currentUser);
    if (!next) return [];
    if (currentUserInvited && data.status === ChatStatus.invited) {
      return [
        inviteReceived(chatId, next),
        notify(
          <NavLink to="/networking/invites">
            {getString('networking.invited-by')}
            {next.inviterProfile?.firstName} {next.inviterProfile?.lastName}
          </NavLink>,
        ),
      ];
    }
    return [chatLoaded(chatId, next)];
  }
  return [chatLoaded(chatId, data)];
}

async function createChatsChannel(query, currentUser) {
  return eventChannel((emit) => {
    let firstLoad = true;
    const mapChanges = (change) => mapInvitesChanges(currentUser, change);
    return query.onSnapshot({
      next(snapshot) {
        if (firstLoad) {
          firstLoad = false;
          Promise.all(
            snapshot.docs.map((doc) => {
              const data = extractDataFromDoc(doc);
              return loadParticipants(data, currentUser);
            }),
          ).then((chats) => {
            emit(chatListLoaded(chats.filter(Boolean)));
          });
          return;
        }
        Promise.all(snapshot.docChanges().map(mapChanges)).then((events) => {
          const allEvents = flatten(events);
          allEvents.forEach((event) => emit(event));
        });
      },
    });
  });
}

function* subscribeToGlobalNotifications() {
  const store = yield getFirestore();
  const appCraftStore = yield getAppCraftStore();
  const currentUser = yield select((state) => state.user.user);
  const ChatsCollectionRef = store
    .collection(NetworkingCollectionKey)
    .doc(appCraftStore.eventId)
    .collection(NetworkingChatsCollectionKey);
  const chatsIWasInvitedTo = ChatsCollectionRef.where('invited', '==', currentUser._id).orderBy(
    'createdAt',
    'asc',
  );
  const chatsImTheInviter = ChatsCollectionRef.where('inviter', '==', currentUser._id).orderBy(
    'createdAt',
    'asc',
  );

  const channelInvited = yield createChatsChannel(chatsIWasInvitedTo, currentUser);
  const invitedTask = yield pipeChannel(channelInvited);
  const channelInviter = yield createChatsChannel(chatsImTheInviter, currentUser);
  const inviterTask = yield pipeChannel(channelInviter);

  yield all([invitedTask, inviterTask]);
}

const maxNotificationsRetry = 10;
const timeBetweenRetries = 200;

function* startNotificationChannel() {
  try {
    yield race({
      task: retry(maxNotificationsRetry, timeBetweenRetries, subscribeToGlobalNotifications),
      stop: take(LOGOUT),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Unable to connect notifications.', e);
  }
}

function* acceptInvite(event) {
  const firestore = yield getFirestore();

  const update = {
    status: ChatStatus.accepted,
  };
  const eventId = yield select((state) => state.user.user?.eventId);
  yield firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(event.payload.chatId)
    .update(update);
}

function* refuseInvite(event) {
  const firestore = yield getFirestore();
  const eventId = yield select((state) => state.user.user?.eventId);
  yield firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(event.payload.chatId)
    .update({
      status: ChatStatus.rejected,
    });
  yield put(chatRemoved(event.payload.chatId));
}

function* sendInvite(event) {
  const firestore = yield getFirestore();
  const self = yield select((state) => state.user.user);

  const chat = {
    inviter: self._id,
    invited: event.payload.invited._id,
    status: ChatStatus.invited,
    createdAt: new Date().toISOString(),
    message: event.payload.message,
  };

  const chatId = [chat.invited, chat.inviter].sort().join('-');
  const ref = firestore
    .collection(NetworkingCollectionKey)
    .doc(self.eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chatId);

  yield ref.set(chat);
  yield put(inviteSent(chatId, chat));
}

export default function* invitesSagas() {
  yield all([
    takeLatest(LOGIN, startNotificationChannel),
    takeEvery(ACCEPT_INVITE, acceptInvite),
    takeEvery(REFUSE_INVITE, refuseInvite),
    takeEvery(SEND_INVITE, sendInvite),
  ]);
}
