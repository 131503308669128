import cx from 'classnames';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import Banner from '../../headers/blocks/Banner';
import BannerAds from '../../home/blocks/BannerAds';
import CloudTvBlock from '../../home/blocks/CloudTvBlock';
import CountdownBlock from '../../home/blocks/CountdownBlock';
import FlocklerBlock from '../../home/blocks/FlocklerBlock';
import NextLiveCountdownBlock from '../../home/blocks/NextLiveCountdownBlock';
import SocialWallWidgetBlock from '../../home/blocks/SocialWallWidgetBlock';
import WorkshopsWithRegistrationBlock from '../../home/blocks/WorkshopsWithRegistrationBlock';
import UpcomingSessions from '../../home/components/UpcomingSessions';
import SocialWallBlock from '../../social-wall/blocks/SocialWallBlock';
import Blocks from '../Blocks';
import ActionsBlock from './ActionsBlock';
import BlockWrapper from './BlockWrapper.tsx';
import BrowserCheckBlock from './BrowserCheckBlock';
import ContactBlock from './ContactBlock';
import CTATileBlock from './CTATileBlock';
import CTATilesBlock from './CTATilesBlock';
import FilesBlock from './FilesBlock';
import HtmlBlock from './HtmlBlock';
import IframeBlock from './IframeBlock';
import ImageBlock from './ImageBlock';
import ItemGridBlock from './ItemGridBlock';
import LogosBlock from './LogosBlock';
import ReplayBlock from './ReplayBlock';
import VideoBlock from './VideoBlock';
import VideosBlock from './VideosBlock';
import WorkshopFilesBlock from './WorkshopFilesBlock';
import CMSWorkshopLiveBlock from './CMSWorkshopLiveBlock';
import WorkshopVideoBlock from './WorkshopVideoBlock';
import CarouselBlock from './CarouselBlock';
import FaqBlock from './FaqBlock';

export const CmsBlocks = ({ blocks }) => (
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <Blocks blocks={blocks} blockComponents={cmsFields} />
);

// eslint-disable-next-line no-use-before-define
const RowBlock = ({ blocks, className }) => (
  <Grid>
    <Grid.Row className={cx('cms-page--block', 'column--row', className)} stretched>
      <CmsBlocks blocks={blocks} />
    </Grid.Row>
  </Grid>
);

const ColumnBlock = ({ width, blocks, className }) => (
  <Grid.Column
    className={cx('cms-page--block', `column--block`, className)}
    width={width}
    style={{ flex: width ? undefined : 1 }}
  >
    <CmsBlocks blocks={blocks} />
  </Grid.Column>
);

export const cmsFields = {
  actions: ActionsBlock,
  banner: Banner,
  bannerAds: BannerAds,
  blockContainer: BlockWrapper,
  browserCheck: BrowserCheckBlock,
  carousel: CarouselBlock,
  cloudTv: CloudTvBlock,
  column: ColumnBlock,
  contact: ContactBlock,
  countdown: CountdownBlock,
  ctaTile: CTATileBlock,
  ctaTiles: CTATilesBlock,
  flockler: FlocklerBlock,
  faq: FaqBlock,
  files: FilesBlock,
  html: HtmlBlock,
  iframe: IframeBlock,
  image: ImageBlock,
  itemGrid: ItemGridBlock,
  logos: LogosBlock,
  nextLiveCountdown: NextLiveCountdownBlock,
  replay: ReplayBlock,
  row: RowBlock,
  socialWall: SocialWallBlock,
  socialWallWidget: SocialWallWidgetBlock,
  upcomingSessions: UpcomingSessions,
  video: VideoBlock,
  videos: VideosBlock,
  workshopFiless: WorkshopFilesBlock,
  workshopLive: CMSWorkshopLiveBlock,
  workshopVideo: WorkshopVideoBlock,
  workshopsWithRegistration: WorkshopsWithRegistrationBlock,
};
