import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import './avatars.scss';
import { DetailsVariant, SimpleVariant } from './variants';
import { bem } from '../../core/design/bem';

const css = bem('AvatarsBlock');

const componentVariants = {
  simple: SimpleVariant,
  details: DetailsVariant,
};

const Avatars = ({ users, size, imageSize, imageKey, maxCount, variant, showModal }) => {
  const finalUsers = users.slice(0, maxCount);
  const AvatarComponent = get(componentVariants, variant, SimpleVariant);
  return (
    <div className={css({ variant }).mix('avatars')}>
      <AvatarComponent
        users={finalUsers}
        size={size}
        imageSize={imageSize}
        imageKey={imageKey}
        showModal={showModal}
      />
    </div>
  );
};

Avatars.defaultProps = {
  imageKey: 'thumbnail',
  maxCount: 5,
  imageSize: 28,
  size: undefined,
  showModal: false,
  variant: 'simple',
};

Avatars.propTypes = {
  imageKey: PropTypes.string,
  imageSize: PropTypes.number,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
  maxCount: PropTypes.number,
  showModal: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.oneOf(['simple', 'details']),
};

export default Avatars;
