import React from 'react';
import loadable from '@loadable/component';
import { useDesignConfig } from '../config/design.context';
import layoutProps from './layoutProps';

const layouts = {
  default: loadable(() =>
    import(/* webpackChunkName: "layout-default" */ './Default/DefaultLayout'),
  ),
  "video-dassault": loadable(() =>
    import(/* webpackChunkName: "layout-video-dassault" */ './VideoDassault/VideoDassaultLayout'),
  ),
};

const Layout = (props) => {
  const { layout } = useDesignConfig();
  const Renderer = layouts[layout] || layouts.default;

  return <Renderer {...props} />;
};

Layout.propTypes = layoutProps;

export default Layout;
