import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const FooterLink = ({ className, text, ...props }) => {
  return (
    <a
      className={cx('footer__link', className)}
      target="_blank"
      rel="noreferrer noopener"
      {...props}
    >
      {text}
    </a>
  );
};

FooterLink.defaultProps = {
  className: undefined,
};

FooterLink.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default FooterLink;
