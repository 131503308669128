import PropTypes from 'prop-types';
import React from 'react';
import { imageProptypes } from '../propTypes';
import Images from '../utils/Images';
import './page-background.scss';

function getPageBackgroundStyle({ image, color, position }) {
  const hasImage = Images.exists(image);
  if (!hasImage && !color) return null;
  if (hasImage)
    return {
      backgroundImage: `url(${Images.maxWidth(image, 1920)})`,
      backgroundPosition: position,
    };
  if (color) return { backgroundColor: color };
  return {};
}

function PageBackground({ image, video, color, position }) {
  if (video && video.uri) {
    return (
      <div className="page-background page-background--video">
        <video title="background-video" muted autoPlay loop>
          <source src={video.uri} type="video/mp4" />
        </video>
      </div>
    );
  }
  if (image && image.type === 'video') {
    return (
      <div className="page-background page-background--video">
        <iframe title="background-video" src={image.uri} allow="autoplay" />
      </div>
    );
  }
  const style = getPageBackgroundStyle({ image, color, position });
  if (!style) return null;
  return <div className="page-background" style={style} />;
}

PageBackground.defaultProps = {
  color: undefined,
  image: undefined,
  position: 'center center',
  video: undefined,
};

PageBackground.propTypes = {
  color: PropTypes.string,
  image: imageProptypes,
  position: PropTypes.string,
  video: PropTypes.shape({ uri: PropTypes.string }),
};

export default PageBackground;
