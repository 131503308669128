import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';
import { ClickableImage } from '../../../headers/blocks/Banner';
import { logoArrayPropTypes, logoPropType } from '../../../propTypes';
import BlockContainer from '../../BlockContainer';
import { cdnImagePropTypes } from '../../CdnImage';
import ItemCardGroup from '../../ItemCardGroup';
import Query from '../../Query';
import './LogosBlock.scss';

const LogoCard = (props) => {
  const { item, trackClick, maxWidth, maxHeight, func } = props;
  const { image, link, entity } = item;
  return (
    <Card>
      <ClickableImage
        image={image}
        link={link}
        entity={entity}
        trackClick={trackClick}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        func={func}
      />
    </Card>
  );
};
LogoCard.defaultProps = {
  trackClick: undefined,
  maxWidth: undefined,
  maxHeight: 500,
  func: 'fit',
};
LogoCard.propTypes = {
  item: logoPropType.isRequired,
  trackClick: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  func: cdnImagePropTypes.func,
};

const Logos = (props) => {
  const { containerType, header, logos, itemsPerRow, groupBy } = props;
  if (!logos) return null;

  return (
    <BlockContainer className="block--logos" header={header} type={containerType}>
      <ItemCardGroup
        centered
        groupClassName="logos"
        items={logos}
        itemsPerRow={itemsPerRow}
        groupBy={groupBy}
        component={LogoCard}
      />
    </BlockContainer>
  );
};

Logos.defaultProps = {
  containerType: 'headerless',
  groupBy: undefined,
  header: undefined,
  itemsPerRow: undefined,
};

Logos.propTypes = {
  containerType: PropTypes.string,
  groupBy: PropTypes.string,
  header: PropTypes.string,
  itemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logos: logoArrayPropTypes.isRequired,
};

const LogosBlock = (props) => {
  const { query } = props;

  if (query && query.from) {
    return (
      <Query {...query}>
        {(items) => <Logos {...props} logos={items} />}
      </Query>
    );
  }

  return <Logos {...props} />;
};

LogosBlock.defaultProps = {
  containerType: 'headerless',
  groupBy: undefined,
  header: undefined,
  itemsPerRow: undefined,
  logos: undefined,
  query: undefined,
};

LogosBlock.propTypes = {
  containerType: PropTypes.string,
  groupBy: PropTypes.string,
  header: PropTypes.string,
  itemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logos: logoArrayPropTypes,
  query: PropTypes.shape({
    select: PropTypes.object,
    from: PropTypes.shape({
      kind: PropTypes.string,
      collection: PropTypes.string,
    }).isRequired,
  }),
};

export default LogosBlock;
