import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userAgenda } from '../agenda/store/agenda.selectors';
import store from '../shared/Store';
import { fetchAppointments, fetchTimeSlots } from '../store/actions';
import { checkUserAvailability } from '../utils/agendaUtils';
import { computeTimeSlots } from './utils';

export function useFreeTimeSlots(collection, userId) {
  useEffect(() => {
    store.reduxStore.dispatch(fetchTimeSlots(collection, userId));
  }, [collection, userId]);
  return (
    useSelector((state) => state.timeSlots.timeSlots[userId]) || {
      isBookingAvailable: false,
      freeTimeSlots: [],
    }
  );
}

export function useCurrentAppointment(collection, groupId) {
  useEffect(() => {
    store.reduxStore.dispatch(fetchAppointments());
  }, [collection, groupId]);
  const appointments = useSelector((state) => state.appointments.appointments);
  if (groupId) {
    // check if user has already booked an appointment with the group
    return appointments.find((appointment) => appointment.groupId === groupId);
  }
  // check if user has already booked an appointment of x collection
  return appointments.find((appointment) => appointment.collection === collection);
}

export function useDaysWithSlots(days, freeTimeSlots) {
  const userEvents = useSelector(userAgenda);
  return useMemo(() => {
    return days.map((day) => ({
      ...day,
      daySlots: day.halfDays.map((grp) => {
        const slots = computeTimeSlots(grp.startDate, grp.endDate, 15, freeTimeSlots);
        const finalSlots = slots.map((slot) => {
          const isUserAvailable = checkUserAvailability(userEvents, slot);
          return {
            ...slot,
            isAvailable: slot.isAvailable,
            isUserAvailable,
          };
        });
        return {
          ...grp,
          slots: finalSlots,
          finalSlots,
        };
      }),
    }));
  }, [days, freeTimeSlots, userEvents]);
}
