import { SET } from './workshopSessions.types';

const initialState = {
  workshopSession: [],
};

export default function reduceWorkshopSession(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return { workshopSessions: action.workshopSessions };
    }
    default:
      return state;
  }
}
