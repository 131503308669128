import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'semantic-ui-react';

import { userProptypes } from '../../../propTypes';
import { SpeakerHeader } from '../../../speakers/Speaker';

const SpeakerModal = (props) => {
  const { speaker, onClose } = props;
  return (
    <Modal size="small" className="speaker__modal" open closeIcon onClose={onClose}>
      <Modal.Content scrolling>
        <SpeakerHeader speaker={{ ...speaker, description: speaker?.fullDescription }} />
      </Modal.Content>
    </Modal>
  );
};

SpeakerModal.propTypes = {
  speaker: PropTypes.shape(userProptypes).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SpeakerModal;
