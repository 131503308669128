import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Segment } from 'semantic-ui-react';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';

const CTABlock = ({ item, field, strings, eventTag }) => {
  const { trackEvent } = useTracking();

  const url = item[field];
  if (!url) return null;

  function trackGoToPage() {
    trackEvent(eventTag, {
      userId: store.user._id,
      item,
    });
  }

  const { header = 'Link', description, button = 'Go to page' } = strings;

  return (
    <Segment>
      <Header as="h3">{header}</Header>
      {description && <p>{description}</p>}
      <Button
        primary
        fluid
        as="a"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={trackGoToPage}
      >
        {button}
      </Button>
    </Segment>
  );
};

CTABlock.defaultProps = {
  eventTag: eventTags.PROGRAM_GO_TO_PAGE,
};

CTABlock.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  eventTag: PropTypes.string,
  strings: PropTypes.shape({
    header: PropTypes.string.isRequired,
    description: PropTypes.string,
    button: PropTypes.string.isRequired,
  }).isRequired,
};

export default CTABlock;
