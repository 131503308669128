import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import WorkshopsBlock from './WorkshopsBlock';

function filterList(workshops, field, value) {
  if (!workshops) return workshops;

  return workshops.filter((workshop) => {
    const workshopValue = workshop[field];
    if (workshopValue === value) return true;
    if (Array.isArray(workshopValue)) return workshopValue.includes(value);
    return false;
  });
}

const WorkshopsMatchingBlock = ({ workshops, header, headerKey }) => {
  if (!workshops || workshops.length === 0) return null;
  const orderedWorkshops = orderBy(workshops, 'startDate', 'asc');
  return <WorkshopsBlock header={header} headerKey={headerKey} workshopList={orderedWorkshops} />;
};

WorkshopsMatchingBlock.defaultProps = {
  header: undefined,
  headerKey: undefined,
};

WorkshopsMatchingBlock.propTypes = {
  workshops: PropTypes.array.isRequired,
  header: PropTypes.string,
  headerKey: PropTypes.string,
};

export default connect((state, { field, value }) => ({
  workshops: filterList(state.workshops.workshops, field, value),
}))(WorkshopsMatchingBlock);
