import React from 'react';
import { Grid } from 'semantic-ui-react';
import CTATileBlock from '../CTATileBlock';
import { Image } from '../types';

type CTATileAnimation = 'scale';
type CTATileVariant = 'segment' | 'square';

type CTATileBlockProps = {
  _id: string;
  title: string;
  subtitle?: string;
  icon?: string;
  to: string;
  backgroundImage?: Image;
  animation?: CTATileAnimation;
  variant?: CTATileVariant;
};

type CTATilesBlockProps = {
  blocks?: CTATileBlockProps[];
  className?: string;
  itemGridProps?: any;
  itemsPerRow?: number;
  gridProps?: any;
  animation?: CTATileAnimation;
  style?: Record<string, string>;
  target?: string;
  variant?: CTATileVariant;
};

const CTATilesBlock = ({
  animation,
  variant,
  blocks,
  className,
  gridProps,
  itemGridProps,
  itemsPerRow,
}: CTATilesBlockProps): JSX.Element | null => {
  if (!blocks || blocks.length < 0) return null;

  const centeredGrid = itemsPerRow && blocks.length < itemsPerRow;
  return (
    <Grid
      className={className}
      doubling
      columns={itemsPerRow}
      centered={centeredGrid}
      {...gridProps}
    >
      {blocks.map((block) => (
        <Grid.Column key={block._id}>
          <CTATileBlock
            animation={animation}
            variant={variant}
            {...itemGridProps}
            {...block}
            maxImageWidth={itemsPerRow && itemsPerRow >= 2 ? 600 : 1200}
          />
        </Grid.Column>
      ))}
    </Grid>
  );
};

CTATilesBlock.defaultProps = {
  animation: undefined,
  blocks: [],
  className: undefined,
  itemGridProps: {},
  itemsPerRow: 2,
  gridProps: {},
  target: undefined,
  style: undefined,
  variant: undefined,
};

export default CTATilesBlock;
