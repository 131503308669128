import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import { SET_COLLECTION } from './users.types';

const initialState = {
  users: [], // All users, use with care
  usersById: {}, // All collections, use with care
  collections: {
    // collaborators: { collection: "", users: [], usersById: {} }
  },
};

function cleanUsers(users) {
  return users.map((user) => ({ kind: 'users', ...user }));
}

export default function reduceUsers(state = initialState, action) {
  switch (action.type) {
    case SET_COLLECTION: {
      const { collection } = action;
      const users = cleanUsers(action.users);

      // Update all users
      const collectionStore = {
        collection,
        users,
        usersById: keyBy(action.users, '_id'),
      };
      const collections = {
        ...state.collections,
        [collection]: collectionStore,
      };

      const allUsers = flatten(values(collections).map((col) => col.users));
      const usersById = keyBy(users, '_id');
      return {
        ...state,
        collections,
        users: allUsers,
        usersById,
      };
    }
    default:
      return state;
  }
}
