/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import CountdownCircle from '../../components/CountdownCircle';
import CountdownDate from '../../components/CountdownDate';
import CountdownSquare from '../../components/CountdownSquare';

export type CountdownVariants =
  | 'square'
  | 'circle1'
  | 'circle2'
  | 'circleWithCurvedLabel'
  | 'circleWithLabelOutside'
  | 'date';

type VariantMap = Record<CountdownVariants, (props: any) => JSX.Element | null>;

export const countdownVariants: VariantMap = {
  square: CountdownSquare,
  circle1: CountdownSquare,
  circle2: CountdownCircle,
  circleWithCurvedLabel: (props: any): JSX.Element => <CountdownCircle {...props} curved />,
  circleWithLabelOutside: (props: any): JSX.Element => <CountdownCircle {...props} labelOutside />,
  date: CountdownDate,
};
